import React from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMortarPestle } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <FontAwesomeIcon icon={faMortarPestle} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
